import React from 'react'




const Terms_of_use= () =>{
  return (
    <div 
      style={
        {display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh'
        }}
    >
          <h1>Terms of use Page</h1>
    </div>
  );
};

export default Terms_of_use;