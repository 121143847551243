import React from 'react'

//Import styling
import {Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink} from './NavbarElements';

const NavBar = () => {
  return (
    <>
        <Nav>
            <NavLink to="/">
                <h1>Involved Investments LLC</h1>
            </NavLink>
            
            <Bars />
            <NavMenu>
                <NavLink to="home" activeStyle>
                    Home
                </NavLink>
                <NavLink to="search" activeStyle>
                    Search
                </NavLink>
                <NavLink to="analyze" activeStyle>
                    Analyze
                </NavLink>
                <NavLink to="trends" activeStyle>
                    Trends
                </NavLink>
                <NavLink to="contact_us" activeStyle>
                    Contact Us
                </NavLink>
            </NavMenu>

            <NavBtn>
                <NavBtnLink to='sign_in'>
                    Sign In
                </NavBtnLink>
            </NavBtn>
        </Nav>
    </>
  );
}

export default NavBar

