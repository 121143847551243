import React from 'react'
import styled from "styled-components";
import {Link} from 'react-router-dom';

export default function Email_form() {

    //Temp Variable
    const to_email = "Giovanni@gmail.com";
    const from_email = "user_random@yahoo.com"
    const temp_message = "Hi I was wondering why there are only 20 listings for Detroit, MI. \n Is there a problem with the website \n\nSincerly,\nBob\nContact: Bob@gmail.com 410-111-1234";


  return (
    <Contact_Container>
            <form id='contact_form'>
                <div className='top'>
                    <div className='contact_title'>
                        Contact Us
                    </div>                
                    <input type='text' name='user_name' placeholder='Name'/>
                    <br/>
                    <input type='text' name='user_email' placeholder='Email'/>
                    <br/>
                    <textarea name='message' placeholder='Type Message'/>
                    <br/>                
                    <br/>

                    <Link to='/submit_email_status' style={{ textDecoration: 'none' }}>
                        <button>Submit</button> 
                    </Link>                            
                </div>
            </form>
    </Contact_Container>
  );
}

const Contact_Container = styled.div`
height: 100%;
width: 100%;
margin:0;
display: flex;
flex-direction: column ;
align-items: center ;
justify-content: left;
background: black;
form{
    height: 100rem;
    width: 100rem;
    margin:0;
    display: flex;
    flex-direction: column ;
    justify-content: left ;
    align-items: center ;
    .top{
        height: 100rem;
        width: 100rem;
        display: flex;
        flex-direction: column ;
        align-items: center ;
        justify-content: left ;
        .contact_title{
            padding-top: 5rem;
            padding-bottom: 4rem;
            display: flex;
            justify-content: center ;
            align-items: center ;
            color: #15cdfc;
            font-size: 3.1rem;
            font-weight: bold;
        }
        input{
            height: 100vh ;
            width: 100vw ;
            align-items: center ;
            justify-content: left ;
            width: 450px;
            height: 32px;
            font-size: 1.2rem;
            font-weight: 500;
            font-weight: bold;
            padding-left: .3rem ;
        }
        textarea{            
                height: 450px;
                width: 800px ;
                font-size: 1.05rem;
                font-family: sans-serif;
                font-weight: 400;
                padding-left: .3rem ;
        }
        button{
            width: 15rem;
            height: 4rem;
            align-items: center;
            justify-content: center;
            padding:1.3rem 5rem;
            border-radius: 5rem;
            border: none;
            background-color: #464241;
            color: #15cdfc;;
            font-size: 1.4rem;
            cursor: pointer;
            transition:0.075s ease-in-out ;
            &:hover{
                transform: scale(1.01, 1.01);
                background-color: #615B5A
            }


            }
    }
}

`;


