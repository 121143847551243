import React from 'react';

import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';

//Import Pages
import Home from './Components/Home/temp';
import Search from './Components/Search/temp'
import Analyze from './Components/Analyze/temp';
import Trends from './Components/Trends';
import Sign_In from './Components/Sign_In/temp';
import About from './Components/About/temp';
import Terms_of_use from './Components/Terms_Of_Use/temp';

//Contact US
import Email_form from './Components/Contact_Us/Email_form';
import Submitted_Email from './Components/Contact_Us/Submitted_Email';



function AppRouter() {
    
    return(    
        <Routes>
            <Route exact path="" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/search" element={<Search />} />
            <Route exact path="/analyze" element={<Analyze />} />
            <Route exact path="/trends" element={<Trends />} />
            <Route exact path="/sign_in" element={<Sign_In />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/terms_of_use" element={<Terms_of_use />} />


            <Route path="contact_us" element={<Email_form/>}/>
            <Route path="submit_email_status" element={<Submitted_Email/>}/>

        </Routes>  
    )

}

export default AppRouter