import React from 'react'
import styled from "styled-components";

export default function Submitted_Email() {
  return (
    //Provide authentication at a later date
    <Container>

        <h1>Email Delivered</h1>
        <h4>A team member will reach out to you via email shortly</h4>
    
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column ;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: black;
    gap: 0rem;
    color: whitesmoke;
    h4{
        color: grey;
    }
`;
