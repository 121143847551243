
//Import Routes
import AppRouter from './AppRouter';
import {Route, Switch, Routes, Link, BrowserRouter} from 'react-router-dom';

//Import Components
import NavBar from './Components/Navbar/NavBar';
import FooterNav from './Components/Footer';

function App() {
  return (
    <BrowserRouter>
      <NavBar />

      <AppRouter />

      <FooterNav/>
    </BrowserRouter>
  );
}

export default App;
