import React from 'react'
//Import styling
import {Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink} from './FooterElements';

const FooterNav = () => {
  return (
    <>
        <Nav>
            <NavLink to="/">
                <h1>Involved Investments LLC</h1>
            </NavLink>
            
            <Bars />
            <NavMenu>
                <NavLink to="home" activeStyle>
                    Home
                </NavLink>
                <NavLink to="contact_us" activeStyle>
                    Contact Us
                </NavLink>
                <NavLink to="about" activeStyle>
                    About
                </NavLink>
                <NavLink to="terms_of_use" activeStyle>
                    Terms of Use
                </NavLink>
            </NavMenu>
        </Nav>
    </>
  );
}

export default FooterNav;
